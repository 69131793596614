import React from "react";
import PropTypes from "prop-types";

// bootstrap components
// eslint-disable-next-line import/no-named-default
import { default as BootstrapModal } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function Modal({
  show, onHide, onConfirm, title, body,
}) {
  return (
    <BootstrapModal show={show} onHide={onHide}>
      <BootstrapModal.Header closeButton>
        <BootstrapModal.Title>{title}</BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body>{body}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        {onConfirm == null ? null : <Button variant="success" onClick={onConfirm}>Confirm</Button>}
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Modal.defaultProps = {
  show: false,
  onHide: null,
  onConfirm: null,
  title: "",
  body: "",
};

export default Modal;
