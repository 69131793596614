import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";

// bootstrap components
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// css and other assets
import "./index.css";

// local components and utils
import Config from "../../config";
import TooltipBadge from "../../components/tooltip-badge";
import Modal from "../../components/modal";
import SpeciesDropdown from "./species-dropdown";
import LocationDropdown from "./location-dropdown";
import { useRequests } from "../../hooks/user-context";
import ImageGrid from "../../components/image-grid";
import CommentDropdown from "./comment-dropdown";
import Card from "../../components/card";
import MetadataItem from "../../components/metadata-item";

function ObservationCard({
  observationId,
  dateUpdated,
  images,
  metadata,
  classification,
  comments,
  username,
  hideButtons,
}) {
  const requests = useRequests();
  const [observation, setObservation] = useState({
    observationId, dateUpdated, images, metadata, classification, comments,
  });
  const [showModal, setShowModal] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingError, setFetchingError] = useState(null);

  // prepare title
  const cardHeader = `#${observationId} - ${username}`;
  const _date = moment(dateUpdated, "YYYY-MM-DD");
  const dateUpdatedFormated = _date.isValid() ? _date.format("DD MMM YYYY") : null;

  // prepare images
  const imageUrls = observation.images.map((item) => item.image_url);

  // prepare metadata
  let { habitat, substrate, date } = observation.metadata;
  if (habitat != null) habitat = habitat.name;
  else habitat = "n/a";
  if (substrate != null) substrate = substrate.name;
  else substrate = "n/a";
  if (date != null) date = new Date(date).toLocaleDateString(Config.LOCALE);
  else date = "n/a";
  const { latitude, longitude } = observation.metadata;

  // prepare classification information
  const classificationSpeciesId = observation.classification.species_id;
  const classificationGenusId = observation.classification.genus_id;
  const classificationScientificName = observation.classification.scientific_name;
  const isSpecies = classificationSpeciesId != null;
  const classificationIsMissing = classificationSpeciesId == null && classificationGenusId == null;
  const classificationStatus = observation.classification.status;
  let badgeClassName;
  let tooltipText;
  switch (classificationStatus) {
  case "not_verified":
    badgeClassName = "cf-observation-card-badge-author";
    tooltipText = `Author Verified ${isSpecies ? "Species" : "Genus"}`;
    break;
  case "verification_requested":
    badgeClassName = "cf-observation-card-badge-verification-requested";
    tooltipText = "Verification Requested";
    break;
  case "expert_verified":
    badgeClassName = "bg-success";
    tooltipText = `Expert Verified ${isSpecies ? "Species" : "Genus"}`;
    break;
  default:
    badgeClassName = "cf-observation-card-badge-ai";
    tooltipText = `Not Verified ${isSpecies ? "Species" : "Genus"}`;
  }

  // prepare confirm button callback
  const onModalShow = () => { setShowModal(true); };
  const onModalHide = () => { setShowModal(false); };
  const reloadObservation = () => {
    setIsFetching(true);
    requests.getObservation(observationId)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          setObservation({
            observationId,
            dateUpdated: data.date_updated,
            images: data.images,
            metadata: data.metadata,
            classification: data.classification,
            comments: data.comments,
          });
        } else setFetchingError(data.error || "Unexpected error");
        setIsFetching(false);
      })
      .catch(() => setFetchingError("Unexpected error"));
  };
  const confirmSpecies = () => {
    requests.createExpertClassification(observationId, classificationSpeciesId)
      .then(() => reloadObservation())
      .catch(() => setFetchingError("Unexpected error"));
    onModalHide();
  };

  return (
    <Card
      className={(classificationStatus === "expert_verified") ? "bg-success-subtle" : ""}
      headerClassName={(classificationStatus === "expert_verified") ? "bg-success" : ""}
      header={(
        <>
          <span className="text-body"><b>{cardHeader}</b></span>
          <span className="text-body-secondary">{dateUpdatedFormated}</span>
        </>
      )}
      isFetching={isFetching}
      fetchingError={fetchingError}
    >
      {(isFetching || fetchingError != null) ? null : (
        <>
          <h4 className="cf-observation-card-classification">
            {classificationIsMissing ? null : (
              <TooltipBadge id="classification" tooltip={tooltipText} className={badgeClassName}>
                {classificationScientificName}
              </TooltipBadge>
            )}
          </h4>
          <ImageGrid imageUrls={imageUrls} />
          <Row className="cf-observation-card-text">
            <Col xs={12} md={8} className="cf-observation-card-metadata">
              <MetadataItem label="Habitat:" value={habitat} />
              <MetadataItem label="Substrate:" value={substrate} />
              <MetadataItem label="Date:" value={date} />
              {latitude != null && longitude != null ? (
                <LocationDropdown latitude={latitude} longitude={longitude} />
              ) : null}
            </Col>
            <Col xs={12} md={4} className="cf-observation-card-buttons">
              {hideButtons ? null : (
                <>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={onModalShow}
                    disabled={classificationIsMissing || classificationStatus === "expert_verified"}
                  >
                    Confirm Species
                  </Button>
                  <SpeciesDropdown
                    observationId={observationId}
                    onSpeciesConfirm={reloadObservation}
                  />
                  <Modal
                    show={showModal}
                    onHide={onModalHide}
                    onConfirm={confirmSpecies}
                    title={`Confirm Species for Observation #${observationId}`}
                    body={`
                    This action will create Expert Classification
                    with species "${classificationScientificName}".
                    `}
                  />
                </>
              )}
              <CommentDropdown observation={observation} onSubmit={reloadObservation} />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
}

ObservationCard.propTypes = {
  observationId: PropTypes.number.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  metadata: PropTypes.shape({
    habitat: PropTypes.object,
    substrate: PropTypes.object,
    date: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
  classification: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired,
  hideButtons: PropTypes.bool,
};

ObservationCard.defaultProps = {
  hideButtons: false,
};

export default ObservationCard;
