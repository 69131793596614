import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import Form from "react-bootstrap/Form";

function FormItem({
  controlId,
  label,
  type,
  placeholder,
  info,
  feedback,
  required,
  onChange,
  value,
  isValid,
  className,
}) {
  return (
    <Form.Group className={`mb-3 ${className}`.trim()} controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        isInvalid={!isValid}
      />
      {info == null ? null : <Form.Text className="text-muted">{info}</Form.Text>}
      {feedback == null ? null : (
        <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

FormItem.propTypes = {
  controlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  info: PropTypes.string,
  feedback: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  isValid: PropTypes.bool,
  className: PropTypes.string,
};

FormItem.defaultProps = {
  type: "text",
  placeholder: null,
  info: null,
  feedback: null,
  required: false,
  onChange: null,
  value: undefined,
  isValid: true,
  className: "",
};

export default FormItem;
