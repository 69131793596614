import React from "react";

// css and other assets
import "./footer.css";

function Footer() {
  return (
    <footer className="cf-footer">
      © All rights reserved. Made by
      <a href="https://www.piva-ai.com/">PiVa AI</a>
    </footer>
  );
}

export default Footer;
