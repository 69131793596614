import React from "react";
import PropTypes from "prop-types";

// bootstrap componen
import Badge from "react-bootstrap/Badge";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function TooltipBadge(props) {
  const {
    id, tooltip, className, children, bg,
  } = props;
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
    >
      <Badge bg={bg} className={className}>{children}</Badge>
    </OverlayTrigger>
  );
}

TooltipBadge.propTypes = {
  id: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  bg: PropTypes.string,
};

TooltipBadge.defaultProps = {
  className: "",
  children: null,
  bg: null,
};

export default TooltipBadge;
