import React, { useState } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

// css and other assets
import "./image-grid.css";

// local components and utils
import { addImageUrlSizeParam } from "../tools";

function ImageGrid(props) {
  const [showModal, setShowModal] = useState(false);
  const [firstImage, setFirstImage] = useState(0);
  const { imageUrls } = props;
  const widthLeft = (imageUrls.length <= 1) ? "100%" : "66%";
  const widthRight = (imageUrls.length <= 1) ? "0%" : "33%";

  const imageUrlsMedium = imageUrls.map((item) => addImageUrlSizeParam(item, "medium"));
  const imageUrlsLarge = imageUrls.map((item) => addImageUrlSizeParam(item, "large"));
  const imageUrlsLargeReordered = [
    ...imageUrlsLarge.slice(firstImage),
    ...imageUrlsLarge.slice(0, firstImage),
  ];

  // methods
  const onModalShow = (imageId) => {
    setFirstImage(imageId);
    setShowModal(true);
  };
  const onModalHide = () => { setShowModal(false); };

  return (
    <div className="cf-image-grid">
      <div className="cf-image-grid-left" style={{ width: widthLeft }}>
        <Image
          className="cf-image-grid-image"
          rounded
          src={imageUrlsMedium.length > 0 ? imageUrlsMedium[0] : null}
          onClick={() => onModalShow(0)}
        />
      </div>
      {imageUrlsMedium.length === 1 ? null
        : (
          <div className="cf-image-grid-right" style={{ width: widthRight }}>
            <Image
              className="cf-image-grid-image-small"
              rounded
              src={imageUrlsMedium[1]}
              onClick={() => onModalShow(1)}
            />
            { imageUrlsMedium.length === 2 ? null : (
              <Image
                className="cf-image-grid-image-small"
                rounded
                src={imageUrlsMedium[2]}
                onClick={() => onModalShow(2)}
              />
            )}
          </div>
        )}
      <Modal
        show={showModal}
        onHide={onModalHide}
        fullscreen={false}
        className="cf-image-grid-modal"
      >
        <Modal.Header closeButton />
        <Carousel
          interval={null}
          controls={imageUrlsLarge.length >= 2}
          indicators={imageUrlsLarge.length >= 2}
          variant="light"
          className="cf-image-grid-carousel"
        >
          {imageUrlsLargeReordered.map((item) => (
            <Carousel.Item key={item} className="cf-image-grid-carousel-item">
              <Image
                className="cf-image-grid-carousel-image"
                rounded
                src={item}
                onClick={onModalHide}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal>
    </div>
  );
}

ImageGrid.propTypes = {
  imageUrls: PropTypes.array.isRequired,
};

export default ImageGrid;
