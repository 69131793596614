import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Alert from "react-bootstrap/Alert";

// local components and utils
import CenteredSpinner from "./centered-spinner";

function DataLoader({
  loadData, effectDependencies, setData, fetchingError, children,
}) {
  const [isFetching, setIsFetching] = useState(true);
  const [_fetchingError, setFetchingError] = useState(fetchingError);

  useEffect(() => {
    // load observation list from API and save it to state
    let reset = false;
    setIsFetching(true);
    loadData()
      .then(async (response) => {
        if (!reset) {
          // check if the data were not already loaded in `.then(...)` statement before
          const data = response.loadedData || await response.json();
          if (response.status === 200) setData(data);
          else setFetchingError(data.error || "Unexpected error");
          setIsFetching(false);
        }
      })
      .catch(() => setFetchingError("Unexpected error"));

    return () => { reset = true; };
  }, effectDependencies);

  let body;
  if (isFetching) body = <CenteredSpinner />;
  else if (_fetchingError != null) body = <Alert variant="danger">{_fetchingError}</Alert>;
  else body = children;
  return body;
}

DataLoader.propTypes = {
  loadData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  effectDependencies: PropTypes.array,
  fetchingError: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

DataLoader.defaultProps = {
  effectDependencies: [],
  fetchingError: null,
  children: null,
};

export default DataLoader;
