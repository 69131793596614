// import Config from "../config";

// export function createImageUrl(image, size = "medium") {
// return `${Config.BASE_URL}/image/${image.image_id}?api-key=${Config.API_KEY}&image_size=${size}`;
// }

export function addImageUrlSizeParam(imageUrl, size = "medium") {
  let result;
  if (imageUrl.includes("?")) result = `${imageUrl}&${new URLSearchParams({ image_size: size })}`;
  else result = `${imageUrl}?${new URLSearchParams({ image_size: size })}`;
  return result;
}

export function wait(delaySeconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, delaySeconds * 1000);
  });
}
