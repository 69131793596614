const Config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  LOCALE: "en-AU", // "cs-CZ"
};

// remove trailing slash character "/"
if (Config.BASE_URL.endsWith("/")) Config.BASE_URL = Config.BASE_URL.slice(0, -1);

export default Config;
