import React, { useState } from "react";

// bootstrap components
import Alert from "react-bootstrap/Alert";

// css and other assets

// local components and utils
import PropTypes from "prop-types";
import Paginator from "./paginator";
import DataLoader from "./data-loader";
import SearchBar from "./search-bar";

function AdvancedDataLoader({
  hasData,
  setData,
  loadData,
  totalPages,
  fetchingError,
  noDataMessage,
  children,
}) {
  const [filterName, setFilterName] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const _loadData = () => loadData(filterName, pageNum);

  return (
    <>
      <SearchBar
        value={filterName}
        onChange={(e) => {
          setFilterName(e.target.value);
          setPageNum(0);
        }}
      />
      <DataLoader
        loadData={_loadData}
        setData={setData}
        effectDependencies={[filterName, pageNum]}
        fetchingError={fetchingError}
      >
        {!hasData ? null : (
          <>
            {children}
            {(totalPages === 0) ? (
              <Alert variant="info">{noDataMessage}</Alert>
            ) : (
              <Paginator
                pageNum={pageNum}
                totalPages={totalPages}
                onClick={(i) => setPageNum(i - 1)}
              />
            )}
          </>
        )}
      </DataLoader>
    </>
  );
}

AdvancedDataLoader.propTypes = {
  hasData: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  fetchingError: PropTypes.string,
  noDataMessage: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AdvancedDataLoader.defaultProps = {
  totalPages: null,
  fetchingError: null,
  noDataMessage: "No data found",
  children: null,
};

export default AdvancedDataLoader;
