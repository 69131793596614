import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

// bootstrap components
import Stack from "react-bootstrap/Stack";
import BootstrapNavbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";

// css and other assets
import "./navbar.css";

// local components and utils
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Container from "./container";
import Modal from "./modal";
import { useRequests } from "../hooks/user-context";
import CenteredSpinner from "./centered-spinner";

function Link({ href, value }) {
  const location = useLocation();
  const navigate = useNavigate();
  const activeClassName = (href === location.pathname) ? "cf-navbar-link-active" : "";
  return (
    <Nav.Link className={`cf-navbar-link ${activeClassName}`.trim()} onClick={() => navigate(href)}>
      {value}
    </Nav.Link>
  );
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function downloadCsv(fileData, filename) {
  const blob = new Blob([fileData], { type: "text/csv" });

  // create a download link
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);

  // trigger the download
  link.click();

  // remove the link from the document
  document.body.removeChild(link);
}

function ObservationCsvDownload() {
  const requests = useRequests();
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingError, setFetchingError] = useState(null);

  const handleCsvDownload = () => {
    setIsFetching(true);
    setFetchingError(null);
    requests.createObservationExport()
      .then(async (response) => {
        if (response.ok) {
          const data = await response.text();
          downloadCsv(data, "observation_export.csv");
        } else {
          const data = await response.json();
          setFetchingError(data.error || "Unexpected error");
        }
        setIsFetching(false);
      })
      .catch(() => {
        setIsFetching(false);
        setFetchingError("Unexpected error");
      });
  };

  return (
    <Stack className="align-items-center">
      {fetchingError == null ? null : (<Alert variant="danger">{fetchingError}</Alert>)}
      {isFetching ? (
        <CenteredSpinner />
      ) : (
        <Button onClick={handleCsvDownload}>Export CSV</Button>
      )}
    </Stack>
  );
}

function NavBar({ user, onLogOut }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isLoggedIn = user != null;

  const handleDropdownShow = () => setShowDropdown(true);
  const handleDropdownHide = () => setShowDropdown(false);

  const handleModalShow = () => setShowModal(true);
  const handleModalHide = () => setShowModal(false);

  return (
    <BootstrapNavbar expand="lg" className="cf-navbar">
      <Container className="cf-navbar-container">
        <BootstrapNavbar.Brand href="/" className="cf-navbar-title">
          CheckFungi
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" className="bg-primary" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav" className="cf-navbar-collapse">
          <Nav className="cf-navbar-group">
            {isLoggedIn ? (
              <>
                <Link href="/" value="Fungi Atlas" />
                <Link href="/identification" value="Identification" />
                <Link href="/my-observations" value="My Observation" />
                {user.userType === "expert" || user.userType === "admin" ? (
                  <>
                    <Link href="/expert-verification" value="Expert Verification" />
                    <Link href="/all-observations" value="All Observations" />
                  </>
                ) : null}
              </>
            ) : null}
          </Nav>
          <Nav className="cf-navbar-group">
            {isLoggedIn ? (
              <Dropdown
                as={Nav.Item}
                show={showDropdown}
                onMouseEnter={handleDropdownShow}
                onMouseLeave={handleDropdownHide}
                data-bs-theme="dark"
              >
                <Dropdown.Toggle as={Nav.Link} className="cf-navbar-link">
                  {user.username}
                </Dropdown.Toggle>
                <Dropdown.Menu className="m-0">
                  {user.userType === "expert" || user.userType === "admin" ? (
                    <Dropdown.Item onClick={handleModalShow}>Export CSV</Dropdown.Item>
                  ) : null}
                  <Dropdown.Item onClick={onLogOut}>Sign Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link href="/sign-in" value="Sign In" />
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
        <Modal
          show={showModal}
          onHide={handleModalHide}
          title="Export Observation Metadata to CSV"
          body={(<ObservationCsvDownload />)}
        />
      </Container>
    </BootstrapNavbar>
  );
}

NavBar.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    avatarId: PropTypes.number.isRequired,
    userType: PropTypes.string.isRequired,
  }),
  onLogOut: PropTypes.func.isRequired,
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
