import React from "react";
import PropTypes from "prop-types";

// bootstrap components

// css and other assets
import "./card-list.css";

// local components and utils

function CardList({ children }) {
  return (
    <div className="cf-card-list">{children}</div>
  );
}

CardList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CardList.defaultProps = {
  children: null,
};

export default CardList;
