import React, { useState } from "react";

// bootstrap components

// css and other assets
import "./index.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import ObservationCard from "../../data-components/observation-card";
import CardList from "../../components/card-list";
import AdvancedDataLoader from "../../components/advanced-data-loader";

function MyObservations() {
  const requests = useRequests();
  const [observationData, setObservationData] = useState(null);

  // methods
  const loadData = (filterName, pageNum) => (
    requests.getSavedObservationList(filterName, pageNum)
  );

  // post-process observation data
  const totalPages = (observationData != null) ? observationData.total_pages : null;
  const observationList = (observationData != null) ? observationData.observation_list : null;

  return (
    <div className="cf-my-observations">
      <AdvancedDataLoader
        hasData={observationData != null}
        setData={setObservationData}
        loadData={loadData}
        totalPages={totalPages}
        noDataMessage="No observations"
      >
        {observationData == null ? null : (
          <CardList>
            {observationList.map((item) => (
              <ObservationCard
                key={item.observation_id}
                observationId={item.observation_id}
                dateUpdated={item.date_updated}
                images={item.images}
                metadata={item.metadata}
                classification={item.classification}
                comments={item.comments}
                username={item.username}
                hideButtons
              />
            ))}
          </CardList>
        )}
      </AdvancedDataLoader>

    </div>
  );
}

export default MyObservations;
