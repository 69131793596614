import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// css and other assets
import "./metadata-item.css";

// local components and utils

function MetadataItem({ label, value }) {
  return (
    <span>
      <b className="cf-metadata-item-label text-body">{label}</b>
      <OverlayTrigger
        overlay={<Tooltip id={label}>{value}</Tooltip>}
      >
        <span className="cf-metadata-item-value text-body">{value}</span>
      </OverlayTrigger>
    </span>
  );
}

MetadataItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

MetadataItem.defaultProps = {
  value: "n/a",
};

export default MetadataItem;
