import React, { useState } from "react";
import moment from "moment";

// bootstrap components
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// css and other assets
import "./index.css";

// local components and utils
import ImageInput from "./image-input";
import { useRequests } from "../../hooks/user-context";
import MetadataSelect from "../../data-components/metadata-select";
import FormItem from "../../components/form-item";
import SpeciesSelect from "./species-select";
import { isSuccess } from "../../rest";

function Identification() {
  const requests = useRequests();
  const [observationId, setObservationId] = useState(null);
  const [imageIds, setImageIds] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [fetchingError, setFetchingError] = useState(null);
  const [isSaved, setIsSaved] = useState(false);

  const isButtonDisabled = imageIds.length === 0 || !isDateValid || isSaved === true;

  const saveImage = (file) => {
    if (observationId == null) { // create observation
      requests.createObservationWithImage(file)
        .then(async (response) => {
          const data = await response.json();
          if (isSuccess(response.status)) {
            setObservationId(data.observation_id);
            setImageIds([data.image_id]);
          } else setFetchingError(data.error || "Unexpected error");
        })
        .catch(() => setFetchingError("Unexpected error"));
    } else { // add image to the existing observation
      requests.createObservationImage(observationId, file)
        .then(async (response) => {
          const data = await response.json();
          if (isSuccess(response.status)) setImageIds([...imageIds, data.image_id]);
          else setFetchingError(data.error || "Unexpected error");
        })
        .catch(() => setFetchingError("Unexpected error"));
    }
  };
  const onDatePickerChange = (event) => {
    const date = event.target.value || null; // set null instead of ""
    if (date != null) {
      const dateParsed = moment(date, "D/M/YYYY", true);
      setIsDateValid(dateParsed.isValid());
    } else setIsDateValid(true);
  };
  const saveObservation = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (observationId != null) {
      const habitat = Number(event.target.habitat.value) || null; // set null instead of ""
      const substrate = Number(event.target.substrate.value) || null; // set null instead of ""
      const date = event.target.date.value || null; // set null instead of ""
      let dateParsed = moment(date, "D/M/YYYY", true);
      dateParsed = dateParsed.isValid() ? dateParsed.format("YYYY-MM-DD") : null;
      const speciesId = Number(event.target.speciesId.value) || null; // set null instead of ""

      // set metadata
      if (habitat != null || substrate != null || dateParsed != null) {
        const response = await requests.updateObservation(
          observationId,
          habitat,
          substrate,
          dateParsed,
        );
        if (!isSuccess(response.status)) {
          const data = await response.json();
          setFetchingError(data.error || "Unexpected error");
          return;
        }
      }

      // set user classification
      if (speciesId != null) {
        const response = await requests.createAuthorClassification(observationId, speciesId);
        if (!isSuccess(response.status)) {
          const data = await response.json();
          setFetchingError(data.error || "Unexpected error");
          return;
        }
      }

      // save observation
      const response = await requests.saveObservation(observationId);
      if (!isSuccess(response.status)) {
        const data = await response.json();
        setFetchingError(data.error || "Unexpected error");
        return;
      }

      setIsSaved(true);
    }
  };

  return (
    <Card className="cf-identification">
      <Card.Header className="cf-identification-header"><b>Identification</b></Card.Header>
      <ImageInput onImageSelect={saveImage} />
      <Form noValidate onSubmit={saveObservation}>
        <div className="cf-identification-form-group">
          <MetadataSelect metadataType="habitat" />
          <MetadataSelect metadataType="substrate" />
          <FormItem
            controlId="date"
            label="Select Date"
            placeholder="dd/mm/yyyy"
            info="Input date in format dd/mm/yyyy."
            feedback="Date should be in format dd/mm/yyyy."
            onChange={onDatePickerChange}
            isValid={isDateValid}
          />
        </div>
        {observationId == null ? null : (
          <div className="cf-identification-form-group">
            <SpeciesSelect
              observationId={observationId}
              imageIds={imageIds}
            />
          </div>
        )}
        <div className="cf-identification-form-group">
          {!isSaved ? null : (
            <Alert variant="success">Observation was successfully saved.</Alert>
          )}
          {fetchingError == null ? null : (
            <Alert variant="danger">{fetchingError}</Alert>
          )}
          <Button variant="primary" type="submit" disabled={isButtonDisabled}>
            Save Observation
          </Button>
        </div>
      </Form>
    </Card>
  );
}

export default Identification;
