import React, {
  useContext, createContext, useMemo,
} from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// local components and utils
import useLocalStorage from "./local-storage";
import Requests from "../rest";

// React Context that propagates props to all components
const UserContext = createContext(null);
const defaultUserContext = {
  language: "en",
  token: null,
  user: null,
};

export function UserContextProvider({ children }) {
  const [userContext, setUserContext] = useLocalStorage("userContext", defaultUserContext);
  // Since the object (and arrays) in render are created every render,
  // they lose the referential equality and hance any components connected to this context
  // will need to refresh.
  // To get around this in a functional component, you can use useMemo to memoise the value
  // and refresh only when one of these values change.
  const providerValue = useMemo(() => ([userContext, setUserContext]), [userContext]);
  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function useUserContext() {
  return useContext(UserContext);
}

export function useAuth() {
  const navigate = useNavigate();
  const [userContext, setUserContext] = useUserContext();

  const login = (data) => {
    setUserContext({ ...userContext, token: data.access_token, user: data.user });
    navigate("/", { replace: true });
  };

  const logout = () => {
    setUserContext({ ...userContext, token: null, user: null });
    navigate("/sign-in", { replace: true });
  };

  return [login, logout];
}

export function useRequests() {
  const [userContext] = useUserContext();
  const [, logout] = useAuth();
  const requests = new Requests(userContext, logout);
  return requests;
}
