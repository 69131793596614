import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import Form from "react-bootstrap/Form";

// css and other assets
import "./search-bar.css";

// local components and utils

function SearchBar({ className, value, onChange }) {
  return (
    <Form.Control
      className={`cf-search-bar ${className}`.trim()}
      autoFocus
      placeholder="Filter by species name or common name ..."
      onChange={onChange}
      value={value || ""}
    />
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

SearchBar.defaultProps = {
  className: "",
  value: "",
  onChange: null,
};

export default SearchBar;
