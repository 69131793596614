import React, { useEffect } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";

// css and other assets
import "./app.scss";

// local components and utils
import Navbar from "./components/navbar";
import Container from "./components/container";
import Footer from "./components/footer";
import ExpertVerification from "./pages/expert-verification";
import AllObservations from "./pages/all-observations";
import MyObservations from "./pages/my-observations";
import Identification from "./pages/identification";
import LogIn from "./pages/login";
import SpeciesList from "./pages/species-list";
import {
  UserContextProvider, useUserContext, useAuth, useRequests,
} from "./hooks/user-context";
import { isSuccess } from "./rest";

function AppLayout() {
  const [userContext, setUserContext] = useUserContext();
  const [, logout] = useAuth();
  const requests = useRequests();

  // load current user data and check if the user is active
  useEffect(() => {
    let reset = false;
    requests.currentUser()
      .then(async (response) => {
        if (!reset) {
          if (isSuccess(response.status)) {
            const data = await response.json();
            if (!data.is_active) logout();
            else setUserContext({ ...userContext, user: data });
          } else logout();
        }
      });
    return () => { reset = true; };
  }, []);

  let user = null;
  if (userContext.token != null && userContext.user != null) {
    user = {
      username: userContext.user.username,
      avatarId: userContext.user.avatar_id,
      userType: userContext.user.user_type,
    };
  }
  return (
    <>
      <Navbar user={user} onLogOut={logout} />
      <Container className="app-container"><Outlet /></Container>
      <Footer />
    </>
  );
}

function OpenLayout() {
  const [userContext] = useUserContext();
  return userContext.token != null ? <Navigate to="/" /> : <Outlet />;
}

function BasicUserLayout() {
  const [userContext] = useUserContext();
  return userContext.token == null ? <Navigate to="/sign-in" replace /> : <Outlet />;
}

function ExpertUserLayout() {
  const [userContext] = useUserContext();
  let output;
  if (userContext.token == null) output = <Navigate to="/sign-in" replace />;
  else if (userContext.user.user_type === "basic") output = <Navigate to="/" replace />;
  else output = <Outlet />;
  return output;
}

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [{
      element: <BasicUserLayout />,
      children: [{
        path: "/",
        element: <SpeciesList />,
      }, {
        path: "/identification",
        element: <Identification />,
      }, {
        path: "/my-observations",
        element: <MyObservations />,
      }],
    }, {
      element: <ExpertUserLayout />,
      children: [{
        path: "/expert-verification",
        element: <ExpertVerification />,
      }, {
        path: "/all-observations",
        element: <AllObservations />,
      }],
    }, {
      element: <OpenLayout />,
      children: [{
        path: "/sign-in",
        element: <LogIn />,
      }],
    }],
  },
]);

function App() {
  return (
    <UserContextProvider>
      <RouterProvider router={router} />
    </UserContextProvider>
  );
}

export default App;
