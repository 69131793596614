import React, { useState } from "react";

// bootstrap components
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

// css and other assets
import "./index.css";

// local components and utils
import FormItem from "../../components/form-item";
import { useAuth, useRequests } from "../../hooks/user-context";

function LogIn() {
  const [login] = useAuth();
  const requests = useRequests();
  const [validated, setValidated] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const submit = (event) => {
    const form = event.currentTarget;
    const isValid = form.checkValidity();
    event.preventDefault();
    event.stopPropagation();
    if (isValid === false) {
      setValidated(true);
    } else {
      const username = event.target.username.value;
      const password = event.target.password.value;
      requests.login(username, password)
        .then(async (response) => {
          const data = await response.json();
          if (response.status === 200) {
            login(data);
          } else {
            setValidated(false);
            setLoginError(data.error);
          }
        })
        .catch(() => setLoginError("Unexpected error."));
    }
  };

  return (
    <div className="cf-log-in">
      <h2 className="cf-log-in-header">Sign in to your account</h2>
      {loginError == null ? null : (
        <Alert variant="danger">{loginError}</Alert>
      )}
      <Form noValidate validated={validated} onSubmit={submit} className="cf-log-in-form">
        <FormItem
          controlId="username"
          type="text"
          required
          label="Username / e-mail"
          placeholder="Enter username or e-mail"
          feedback="Please fill the username or e-mail."
        />
        <FormItem
          controlId="password"
          type="password"
          required
          label="Password"
          placeholder="Password"
          feedback="Please fill the password."
        />
        <Button className="cf-log-in-form-button" variant="primary" type="submit">Log In</Button>
      </Form>
    </div>
  );
}

export default LogIn;
