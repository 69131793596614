import React, { useState } from "react";

// bootstrap components

// css and other assets
import "./index.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import SpeciesCard from "./species-card";
import CardList from "../../components/card-list";
import AdvancedDataLoader from "../../components/advanced-data-loader";

function SpeciesList() {
  const requests = useRequests();
  const [speciesData, setSpeciesData] = useState(null);

  // methods
  const loadData = (filterName, pageNum) => requests.getSpeciesList(filterName, pageNum);

  // post-process species data
  const totalPages = (speciesData != null) ? speciesData.total_pages : null;
  const speciesList = (speciesData != null) ? speciesData.species_list : null;

  return (
    <div className="cf-species-list">
      <AdvancedDataLoader
        hasData={speciesData != null}
        setData={setSpeciesData}
        loadData={loadData}
        totalPages={totalPages}
        noDataMessage="No species"
      >
        {speciesData == null ? null : (
          <CardList>
            {speciesList.map((item) => (
              <SpeciesCard
                key={item.species_id}
                speciesName={item.species_name}
                genusName={item.genus_name}
                familyName={item.family_name}
                commonName={item.common_name}
                toxicityStatus={item.toxicity_status}
                imageUrl={item.image_url}
              />
            ))}
          </CardList>
        )}
      </AdvancedDataLoader>
    </div>
  );
}

export default SpeciesList;
