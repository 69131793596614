import React from "react";

// bootstrap components
import Spinner from "react-bootstrap/Spinner";

// css and other assets
import "./centered-spinner.css";
import PropTypes from "prop-types";

function CenteredSpinner({ className }) {
  return (
    <div className={`cf-centered-spinner ${className}`.trim()}>
      <Spinner animation="border" />
    </div>
  );
}

CenteredSpinner.propTypes = {
  className: PropTypes.string,
};

CenteredSpinner.defaultProps = {
  className: "",
};

export default CenteredSpinner;
