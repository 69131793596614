import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import Alert from "react-bootstrap/Alert";

// css and other assets
import "./species-select.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import Paginator from "../../components/paginator";
import DataLoader from "../../components/data-loader";
import FormItem from "../../components/form-item";
import { wait } from "../../tools";

function SpeciesOption({
  speciesId, speciesName, commonName, imageUrl, toxicityStatus, checked, onChange,
}) {
  const radioInputRef = useRef(null);
  let badgeColor;
  let badgeText;
  switch (toxicityStatus) {
  case "edible":
    badgeColor = "success";
    badgeText = "Edible";
    break;
  case "non_edible":
    badgeColor = "warning";
    badgeText = "Non-edible";
    break;
  case "poisonous":
    badgeColor = "danger";
    badgeText = "Poisonous";
    break;
  default:
    badgeColor = "success";
    badgeText = "Edible";
  }
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`cf-species-option ${checked ? "checked" : ""}`.trim()}
      onClick={() => radioInputRef.current.click()}
    >
      <Image rounded src={imageUrl} className="cf-species-option-image" />
      <div className="cf-species-option-text">
        <span><b>{speciesName}</b></span>
        {commonName == null ? null : <span className="text-primary">{commonName}</span>}
        {toxicityStatus == null ? null : <Badge bg={badgeColor}>{badgeText}</Badge>}
      </div>
      <input
        type="radio"
        name="speciesId"
        style={{ display: "none" }}
        id={speciesId}
        value={speciesId}
        ref={radioInputRef}
        onChange={onChange}
      />
    </div>
  );
}

SpeciesOption.propTypes = {
  speciesId: PropTypes.number.isRequired,
  speciesName: PropTypes.string.isRequired,
  commonName: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  toxicityStatus: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

SpeciesOption.defaultProps = {
  commonName: null,
  toxicityStatus: null,
  checked: false,
  onChange: null,
};

function SpeciesSelect({ observationId, imageIds }) {
  const requests = useRequests();
  const [filterName, setFilterName] = useState(null);
  const [pageNum, setPageNum] = useState(0);
  const [speciesData, setSpeciesData] = useState(null);
  const [checkedSpeciesId, setCheckedSpeciesId] = useState(null);

  // post-process species data
  const totalPages = (speciesData != null) ? speciesData.total_pages : null;
  const speciesList = (speciesData != null) ? speciesData.species_list : null;

  const numRetries = 5;
  const delaySeconds = 3;
  const fetchRetry = (numRetriesLeft) => requests.getObservationPrediction(observationId)
    .then(async (response) => {
      const data = await response.json();
      if (data.num_images_pending > 0 && numRetriesLeft > 0) {
        return wait(delaySeconds).then(() => fetchRetry(numRetriesLeft - 1));
      }
      // save loaded data to use it in the following `.then(...)` statements by DataLoader
      response.loadedData = data;
      return response;
    });
  const loadData = () => fetchRetry(numRetries);

  // load data again if predictions are not finished
  // const numImagesPending = (speciesData != null) ? speciesData.num_images_pending : null;

  return (
    <div className="cf-species-select">
      <FormItem
        controlId="species-select-search"
        className="cf-species-select-search"
        label="Select Species"
        type="text"
        placeholder="Filter by species name or common name ..."
        onChange={(e) => {
          setFilterName(e.target.value);
          setPageNum(0);
        }}
        value={filterName || ""}
      />
      <DataLoader
        loadData={loadData}
        setData={setSpeciesData}
        effectDependencies={[imageIds, filterName, pageNum]}
      >
        {speciesData == null ? null : (
          <>
            <div className="cf-species-select-species-list">
              {speciesList.map((item) => (
                <SpeciesOption
                  key={item.species_id}
                  speciesId={item.species_id}
                  speciesName={item.species_name}
                  commonName={item.common_name}
                  imageUrl={item.image_url}
                  toxicityStatus={item.toxicity_status}
                  checked={item.species_id === checkedSpeciesId}
                  onChange={() => setCheckedSpeciesId(item.species_id)}
                />
              ))}
            </div>
            {(totalPages === 0) ? (
              <Alert variant="info">No species</Alert>
            ) : (
              <Paginator
                className="cf-species-select-paginator"
                pageNum={pageNum}
                totalPages={totalPages}
                onClick={(i) => setPageNum(i - 1)}
              />
            )}
          </>
        )}
      </DataLoader>
    </div>
  );
}

SpeciesSelect.propTypes = {
  observationId: PropTypes.number.isRequired,
  imageIds: PropTypes.array.isRequired,
};

export default SpeciesSelect;
