import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";

// bootstrap components
import Dropdown from "react-bootstrap/Dropdown";

// css and other assets
import "./location-dropdown.css";
import "leaflet/dist/leaflet.css";

// local components and utils

const markerIcon = L.divIcon({
  html: `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 16 16"
  width="3em"
  height="3em"
  color="red"
  fill="currentColor"
>
  <path
    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
  />
</svg>`,
  className: "",
  iconSize: [24, 40],
  iconAnchor: [12, 40],
});

// use forwardRef to allow Dropdown to access the DOM of the Menu to measure it
const DropdownMenu = forwardRef(({
  latitude, longitude, className, style,
}, ref) => (
  <div
    ref={ref}
    className={`${className} cf-location-dropdown`.trim()}
    style={style}
  >
    <div className="cf-location-dropdown-inner">
      <MapContainer
        center={[latitude, longitude]}
        zoom={9}
        scrollWheelZoom={false}
        style={{ height: 500, width: 500 }}
      >
        <TileLayer
          // eslint-disable-next-line max-len
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latitude, longitude]} icon={markerIcon} />
      </MapContainer>
    </div>
  </div>
));

DropdownMenu.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

function LocationDropdown({ latitude, longitude }) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-location"
        variant="primary"
        size="sm"
        className="cf-location-dropdown-button"
      >
        View Location
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownMenu}
        latitude={latitude}
        longitude={longitude}
      />
    </Dropdown>
  );
}

LocationDropdown.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default LocationDropdown;
