import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// bootstrap components
import Stack from "react-bootstrap/Stack";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

// css and other assets
import "./comment-dropdown.css";

// local components and utils
import { useRequests, useUserContext } from "../../hooks/user-context";

const messages = [
  "Pro potvrzení určení je třeba vyfotit houbu z více úhlů.",
  "Houbu nelze určit, protože na plodnici chybí nezbytné určovací znaky.",
  "Na fotografii/ích je více různých druhů.",
  "Spolehlivé určení je možné pouze po mikroskopickém/genetickém studiu.",
  "Je houba sbíraná na území ČR? Pokud ne, tak odkud pochází nález?",
];

function Comment({
  username, message, dateUpdated, isUsersMessage,
}) {
  const date = moment(dateUpdated, "YYYY-MM-DD");
  console.log("date", date);
  const dateFormated = date.isValid() ? date.format("DD MMM YYYY") : null;
  return (
    <div className="cf-comment-menu-comment">
      <span><b>{`${username} ${isUsersMessage ? "(me)" : ""}`.trim()}</b></span>
      <span className="text-secondary fw-light fs-6 mb-1">{dateFormated}</span>
      <span className="text-body mb-4">{message}</span>
    </div>
  );
}

Comment.propTypes = {
  username: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  dateUpdated: PropTypes.string.isRequired,
  isUsersMessage: PropTypes.bool.isRequired,
};

// use forwardRef to allow Dropdown to access the DOM of the Menu to measure it
const CommentMenu = forwardRef(({
  observation, onSubmit, className, style,
}, ref) => {
  const requests = useRequests();
  const [userContext] = useUserContext();
  const [message, setMessage] = useState("");
  const [fetchingError, setFetchingError] = useState(null);
  const isButtonDisabled = message.length === 0;
  const { observationId, comments } = observation;

  const createComment = () => {
    const _message = message;
    setMessage("");
    requests.createObservationComment(observationId, userContext.user.user_id, _message)
      .then(() => onSubmit())
      .catch(() => setFetchingError("Unexpected error"));
  };

  const onMessageSelect = (event) => { setMessage(event.target.value); };

  return (
    <div
      ref={ref}
      className={`${className} cf-comment-menu`.trim()}
      style={style}
    >
      <div className="cf-comment-menu-inner">
        <div className="cf-comment-menu-comments">
          {comments.map((item) => (
            <Comment
              key={item.user_id}
              username={item.username}
              message={item.message}
              dateUpdated={item.date_updated}
              isUsersMessage={item.author_id === userContext.user.user_id}
            />
          ))}
        </div>
        {fetchingError == null ? null : (
          <Alert className="mt-2" variant="danger">{fetchingError}</Alert>
        )}
        <Stack direction="column" gap={2} className="flex-grow-0">
          <Form.Select onChange={onMessageSelect}>
            <option key="default" value="">Select message</option>
            {messages.map((item) => (
              <option key={item} value={item}>{item}</option>
            ))}
          </Form.Select>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Leave a comment"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Button
              variant="primary"
              onClick={createComment}
              disabled={isButtonDisabled}
            >
              Send
            </Button>
          </InputGroup>
        </Stack>
      </div>
    </div>
  );
});

CommentMenu.propTypes = {
  observation: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

function CommentDropdown({ observation, onSubmit }) {
  const { comments } = observation;
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-comments"
        variant="primary"
        size="sm"
        className="cf-comment-dropdown-button"
      >
        {`Comments (${comments.length})`}
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={CommentMenu}
        observation={observation}
        onSubmit={onSubmit}
      />
    </Dropdown>
  );
}

CommentDropdown.propTypes = {
  observation: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CommentDropdown;
