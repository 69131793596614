import React from "react";
import PropTypes from "prop-types";

// bootstrap components
// eslint-disable-next-line import/no-named-default
import { default as BootstrapCard } from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

// css and other assets
import "./card.css";

// local components and utils
import CenteredSpinner from "./centered-spinner";

function Card({
  header, isFetching, fetchingError, children, className, headerClassName,
}) {
  let body;
  if (isFetching) body = <CenteredSpinner />;
  else if (fetchingError != null) body = <Alert variant="danger">{fetchingError}</Alert>;
  else body = (<BootstrapCard.Body>{children}</BootstrapCard.Body>);

  return (
    <BootstrapCard className={`cf-card ${className}`.trim()}>
      <BootstrapCard.Header className={`cf-card-header ${headerClassName}`.trim()}>
        {header}
      </BootstrapCard.Header>
      {body}
    </BootstrapCard>
  );
}

Card.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isFetching: PropTypes.bool,
  fetchingError: PropTypes.string,
};

Card.defaultProps = {
  className: "",
  headerClassName: "",
  children: null,
  isFetching: false,
  fetchingError: null,
};

export default Card;
