import React from "react";
import PropTypes from "prop-types";

// css and other assets
import "./container.css";

function Container({ className, children }) {
  return (
    <div className={`cf-container ${className}`.trim()}>{children}</div>
  );
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Container.defaultProps = {
  className: "",
  children: null,
};

export default Container;
