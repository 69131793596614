import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Form from "react-bootstrap/Form";

// local components and utils
import { useRequests } from "../hooks/user-context";

function MetadataSelect({ metadataType }) {
  const requests = useRequests();
  const [value, setValue] = useState("");
  const [metadata, setMetadata] = useState(null);
  const [fetchingError, setFetchingError] = useState(null);

  // postprocess metadata
  let metadataList = null;
  if (metadata != null) {
    metadataList = metadataType === "habitat" ? metadata.habitat_list : metadata.substrate_list;
  }

  const loadData = () => (
    (metadataType === "habitat") ? requests.getHabitatList() : requests.getSubstrateList()
  );
  useEffect(() => {
    // load observation list from API and save it to state
    let reset = false;
    loadData()
      .then(async (response) => {
        if (!reset) {
          const data = await response.json();
          if (response.status === 200) setMetadata(data);
          else setFetchingError(data.error || "Unexpected error");
        }
      })
      .catch(() => setFetchingError("Unexpected error"));

    return () => { reset = true; };
  }, []);

  const onChange = (event) => setValue(event.target.value);
  const selectStyle = value === "" ? { color: "var(--bs-secondary-color)" } : null;

  return (
    <Form.Group className="mb-3" controlId={metadataType}>
      <Form.Label>{metadataType === "habitat" ? "Select Habitat" : "Select Substrate"}</Form.Label>
      <Form.Select onChange={onChange} style={selectStyle}>
        <option key="default" value="">
          {metadataType === "habitat" ? "Select Habitat" : "Select Substrate"}
        </option>
        {metadataList == null ? null : metadataList.map((item) => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </Form.Select>
      {fetchingError == null ? null : (
        <Form.Control.Feedback type="invalid">{fetchingError}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

MetadataSelect.propTypes = {
  metadataType: PropTypes.oneOf(["habitat", "substrate"]).isRequired,
};

export default MetadataSelect;
