import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import Badge from "react-bootstrap/Badge";

// css and other assets
import "./species-card.css";

// local components and utils
import ImageGrid from "../../components/image-grid";
import Card from "../../components/card";
import MetadataItem from "../../components/metadata-item";

function SpeciesCard({
  speciesName, genusName, familyName, toxicityStatus, imageUrl, commonName,
}) {
  let badgeClassName;
  let badgeText;
  switch (toxicityStatus) {
  case "non_edible":
    badgeClassName = "bg-warning";
    badgeText = "Non-edible";
    break;
  case "poisonous":
    badgeClassName = "bg-danger";
    badgeText = "Poisonous";
    break;
  default:
    badgeClassName = "bg-success";
    badgeText = "Edible";
  }
  return (
    <Card
      header={<b>{speciesName}</b>}
    >
      {toxicityStatus == null ? null : (
        <h4 className="cf-species-card-toxicity-status">
          <Badge className={badgeClassName}>{badgeText}</Badge>
        </h4>
      )}
      <ImageGrid imageUrls={[imageUrl]} />
      <div className="cf-species-card-text">
        <MetadataItem label="Species Name:" value={speciesName} />
        <MetadataItem label="Genus Name:" value={genusName} />
        <MetadataItem label="Family Name:" value={familyName} />
        <MetadataItem label="Common Name:" value={commonName} />
      </div>
    </Card>
  );
}

SpeciesCard.propTypes = {
  // speciesId: PropTypes.number.isRequired,
  speciesName: PropTypes.string.isRequired,
  genusName: PropTypes.string,
  familyName: PropTypes.string,
  commonName: PropTypes.string,
  toxicityStatus: PropTypes.string,
  imageUrl: PropTypes.string,
};

SpeciesCard.defaultProps = {
  genusName: "",
  familyName: "",
  commonName: "",
  toxicityStatus: null,
  imageUrl: null,
};

export default SpeciesCard;
