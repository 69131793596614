import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// css and other assets
import "./image-input.css";

// local components and utils
import Image from "react-bootstrap/Image";
import { ReactComponent as CameraPlusIcon } from "../../assets/tabler-icon-camera-plus.svg";

function ImageInputItem({ value, onImageSelect }) {
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const onImageChange = (files) => {
    if (files && files[0]) {
      setImage(URL.createObjectURL(files[0]));
      if (typeof onImageSelect === "function") onImageSelect(files[0]);
    }
  };
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`cf-image-input-item ${image != null ? "added" : ""}`.trim()}
      onClick={() => { if (fileInputRef.current != null) fileInputRef.current.click(); }}
    >
      {image != null ? (
        <Image className="cf-image-input-image" src={image} rounded />
      ) : (
        <>
          <CameraPlusIcon />
          <span>{value}</span>
          <input
            type="file"
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={(e) => onImageChange(e.target.files)}
          />
        </>
      )}
    </div>
  );
}

ImageInputItem.propTypes = {
  value: PropTypes.string.isRequired,
  onImageSelect: PropTypes.func.isRequired,
};

function ImageInput({ onImageSelect }) {
  return (
    <Row className="cf-image-input">
      <Col sm={12} md={4}>
        <ImageInputItem value="Fruiting Body" onImageSelect={onImageSelect} />
      </Col>
      <Col sm={12} md={4}>
        <ImageInputItem value="Leg" onImageSelect={onImageSelect} />
      </Col>
      <Col sm={12} md={4}>
        <ImageInputItem value="Gills/Pores" onImageSelect={onImageSelect} />
      </Col>
    </Row>
  );
}

ImageInput.propTypes = {
  onImageSelect: PropTypes.func.isRequired,
};

export default ImageInput;
